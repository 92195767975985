// Magyar
export const locale = {
	lang: 'hu',
	data: {
		REGISTER: {
			TITLE: 'Regisztráció',
			ALREADY: 'Van már fiókom!',
			INFOTEXT: 'A beírt adatokat kizárólag\n' +
				'                              az\n' +
				'                              adott szolgáltatáshoz használjuk fel, bizalmasan kezeljük,\n' +
				'                              és\n' +
				'                              nem adjuk ki! A Regisztráció gombra kattintva elfogadod\n' +
				'                              Felhasználási feltételeinket, Adatkezelési szabályzatunkat\n' +
				'                              és a\n' +
				'                              cookie-k használatára vonatkozó szabályzatunkat.',
			REQUIRED: 'A csillagos mezők kitöltése kötelező.',
			PERSONALDATA: ' Személyes adatok:',
			FIRSTNAME: 'Vezetéknév',
			TUTELARY: 'Kötelező gondviselő email cím megadása',
			LASTNAME: 'Keresztnév',
			FIRSTNAMEERROR: 'A\n' +
				'                                vezetéknév\n' +
				'                                nem tartalmazhat speciális\n' +
				'                                karaktereket, csak kötőjelet, és minimum 3 és maximum 15\n' +
				'                                karakter hosszúságú',
			LASTNAMEERROR: 'A\n' +
				'                                keresztnév\n' +
				'                                nem tartalmazhat speciális\n' +
				'                                karaktereket, csak kötőjelet, és minimum 3 és maximum 20\n' +
				'                                karakter hosszúságú!',
			MOTHERNAME: 'Anyja neve',
			BIRTHPLACE: 'Születési hely',
			BIRTHDATE: 'Születési idő',
			CLICKHEREPLACEHOLDER: 'Kérem kattintson ide a születési idő beállításához',
			DATATOACCESS: 'Belépéshez szükséges adatok:',
			EMAIL: 'E-mail cím',
			EMAILRE: 'E-mail cím újra',
			EMAILNOTMATCH: 'A két megadott e-mail nem egyezik!',
			EMAILERROR: 'Az email cím\n' +
				'                                nem\n' +
				'                                tartalmazhat ékezetet és nagybetűt, minimum 3 és maximum\n' +
				'                                15\n' +
				'                                karakter hosszú lehet!\n' +
				'                                Kérem valós email címet adjon meg!',
			PASSWORD: 'Jelszó ',
			PASSWORDRE: 'Jelszó újra',
			PASSWORDNOTMATCH: 'A két megadott jelszó nem egyezik!',
			PASSWORDSTRENGTH: 'A jelszó minimum 8 karakter hosszú, tartalmaznia kell kis és nagybetűt, valamint számot.',
			REQUIREDIMAGE: 'Feltölteni kívánt kép (kötelező *):',
			IMAGETOOLTIP: 'Kérem válassza ki a feltölteni kívánt képet!',
			// PRIVACYPOLICYFIRST: 'Regisztrációmmal elfogadom az Adatvédelmi nyilatkozat-ot.',
			//„Regisztrációmmal kijelentem, hogy megismertem és megértettem az Adatkezelési tájékoztatót. Az Adatkezelési tájékoztatót IDE kattintva elolvashatja.”
			PRIVACYPOLICYFIRST: 'Regisztrációmmal kijelentem, hogy megismertem és megértettem az Adatkezelési tájékoztatót.',
			PRIVACYPOLICYSECOND: 'Az Adatkezelési tájékoztatót IDE kattintva elolvashatja.',
			REGISTRATION: 'Regisztráció',
			PLEASELOGIN: 'Kérem jelentkezzen be felhasználónevével és jelszavával:',
			ALREADYEMAIL: 'E-mail cím',
			ALREADYEMAILERROR: 'Az email cím\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tnem\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttartalmazhat ékezetet és nagybetűt, minimum 3 és maximum\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t15\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tkarakter hosszú lehet!\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tKérem valós email címet adjon meg!',
			LOGIN: 'Bejelentkezés',
			BACK: 'Vissza',
			TUTELARYEMAIL: 'Gondviselő e-mail címe'
		},
		AUTH:{
			INPUT:{
				EMAIL: 'E-mail',
				PASSWORD: 'Jelszó'
			}
		}
	}
};
