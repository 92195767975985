export class MenuConfig {
	public defaults: any = {
		header: {
			self: {},

		},
		aside: {
			self: {},
			items: [
				{
					title: 'Információk',
					root: true,
					alignment: 'left',
					icon: 'flaticon-information',
					page: '/information',
					permission: ['FEJLESZTO','ADMIN']
				},
				{
					title: 'Események',
					root: true,
					alignment: 'left',
					icon: 'flaticon-event-calendar-symbol',
					page: '/events',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','USER','ALSUPERVISOR']
				},

				{
					title: 'Felhasználók',
					root: true,
					alignment: 'left',
					icon: 'flaticon-network',
					page: '/users',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','ALSUPERVISOR']
				},
				{
					title: 'Voucher-ek',
					root: true,
					icon: 'la la-barcode',
					page: '/vouchers',
					permission: ['FEJLESZTO','ADMIN']
				},

				{
					title: 'Pass-ok',
					root: true,
					icon: 'la la-file-text',
					page: '/passes',
					permission: ['FEJLESZTO','ADMIN']
				},
				{
					title: 'Karszalagok',
					root: true,
					icon: 'la la-ticket',
					page: '/armband',
					permission: ['FEJLESZTO','ADMIN']
				},

				{
					title: 'Meghívottak',
					root: true,
					alignment: 'left',
					icon: 'flaticon2-group',
					page: '/invited',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','ALSUPERVISOR']
				},
				{
					title: 'Parkolók',
					root: true,
					icon: 'flaticon2-shrink',
					page: '/parking',
					permission: ['FEJLESZTO','ADMIN']
				},
				{
					title: 'Rólunk',
					root: true,
					alignment: 'left',
					icon: 'flaticon-interface-11',
					page: '/aboutus',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','USER','ALSUPERVISOR']
				},
				{
					title: 'Adatkezelés és adatvédelem',
					root: true,
					alignment: 'left',
					icon: 'flaticon-notes',
					page: '/privacypolicy',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','USER','ALSUPERVISOR']
				},
				{
					title: 'Segédletek',
					root: true,
					icon: 'flaticon2-help',
					page: '/helper',
					permission: []
				},
				{
					title: 'Beváltás',
					root: true,
					alignment: 'left',
					icon: 'flaticon-notes',
					page: '/redemption',
					permission: ['PENZTAROS']
				},
				{
					title: 'Csoportos regisztráció',
					root: true,
					alignment: 'left',
					icon: 'flaticon-apps',
					page: '/userimport',
					permission: ['FEJLESZTO','ADMIN','SUPERVISOR','ALSUPERVISOR']
				},
			]
		},
	};

	public get configs(): any {
		return this.defaults;
	}
}
