import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NicknameService} from './nickname.service';

@Component({
	selector: 'kt-nickname',
	templateUrl: './nickname.component.html',
	styleUrls: ['./nickname.component.scss']
})
export class NicknameComponent implements OnInit {
	@Input() public id;
	@Input() public nickName;
	form = this.fb.group({
		nickName: [''],
	});

	constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private fb: FormBuilder, private nickNameService: NicknameService) {
	}

	ngOnInit() {
		if (this.nickName) {
			this.form.setValue({
				nickName: this.nickName
			});
		}
	}

	save() {

		const formData = {
			userId: this.id,
			nickName: this.form.controls['nickName'].value
		};

		this.nickNameService.newNickname(formData).subscribe((res) => {
			if (res.status == 200) {
				this.toastr.success('A nicknevet sikeresen létrehozta', '');
				this.activeModal.close(true);
			} else {
				this.toastr.error('Hiba létrehozás közben', 'Hiba');
			}
		}, (error) => {
			this.toastr.error(error.error.message, 'Hiba');
		});
	}
}
