import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {ForgotPassService} from './forgot-pass.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {baseUrl} from '../../../url.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
	selector: 'kt-forgot-pass',
	templateUrl: './forgot-pass.component.html',
	styleUrls: ['./forgot-pass.component.scss']
})
export class ForgotPassComponent implements OnInit {
	success: boolean = false;
	email = new FormControl();
	// form = this.fb.group({
	// 	email: ['']
	// });

	constructor(private http: HttpClient,private toastr: ToastrService, private fb: FormBuilder, private service: ForgotPassService, public dialogRef: MatDialogRef<ForgotPassComponent>) {
	}

	ngOnInit() {
	}

	onClose(): void {
		this.dialogRef.close();
	}

	forgotPass() {
		const formData = {
			email: this.email.value
		};
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
			})
		};
		this.http.post<any>(baseUrl + 'forgottenPassword', formData, {headers: new HttpHeaders({'Content-Type': 'application/json'})}).subscribe((res) => {
				this.success = true;
			}, error => {
				this.toastr.error(error, '');
			}
		);
		// this.service.forgotPass(formData).subscribe((res) => {
		// 		this.success = true;
		// 	}, error => {
		// 		this.toastr.error(error, '');
		// 	}
		// );
	}
}
