import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {VouchersService} from '../../../vouchers/vouchers.service';
import {UserPermisService} from '../user-permis.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-setpermis',
	templateUrl: './setpermis.component.html',
	styleUrls: ['./setpermis.component.scss']
})
export class SetpermisComponent implements OnInit {
	@Input() public data;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();
	kivalasztottEvent;
	kivalasztottVoucher;
	osszes : Array<any>;
	eventList;
	voucherList;

	constructor(public activeModal: NgbActiveModal,private modalService: NgbModal,private voucherService: VouchersService, private service: UserPermisService) {
	}

	ngOnInit() {
		this.getVoucher();
		this.getEvents();
	}

	getVoucher() {
		this.voucherService.getVouchers(null).subscribe((res) => {

			this.voucherList = res.elemek;
		});
	}
	changeVoucher(event) {
		this.kivalasztottVoucher = event;
	}
	changeEvent(event) {
		this.kivalasztottEvent = event;
	}

	getEvents() {
		let params = new HttpParams();
		params = params.append('id', this.data);
		this.service.getUserEvents(params).subscribe((res) => {
			this.eventList = res;
		});
	}

	getElement(){
		this.osszes = [ this.kivalasztottVoucher, this.kivalasztottEvent];
		this.passEntry.emit(this.osszes);
		this.activeModal.close(true);
	}
}
