import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PrivacypolicyService} from './privacypolicy.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
	selector: 'kt-privacypolicy',
	templateUrl: './privacypolicy.component.html',
	styleUrls: ['./privacypolicy.component.scss']
})
export class PrivacypolicyComponent implements OnInit {
	form = this.fb.group({
		privacyPolicy: ['', Validators.required],
	});
	role: boolean = false;
	privacyConfig = {
		editable: true,
		spellcheck: false,
		translate: 'yes',
		height: '750px',
		minHeight: '750px',
		placeholder: 'Kérem adja meg az adatkezelési és adatvédelmi szabályzatot!',
// 		toolbar: [
// 			['bold', 'italic', 'underline'],
// 			['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
// ]
		toolbar: [
			['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
			[ 'fontSize', 'color'],
			['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
			['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
			['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
			['link', 'unlink', 'image', 'video']
		]
	};

	constructor(private ngxPermissionService: NgxPermissionsService, private changeDetector: ChangeDetectorRef, private toastr: ToastrService, private fb: FormBuilder, private service: PrivacypolicyService) {
	}

	ngOnInit() {
		this.getPolicy();
		this.role = this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO');
		if(!this.role){
			this.privacyConfig ={
				height: "750px",
				minHeight: "750px",
				placeholder: "Kérem adja meg az adatkezelési és adatvédelmi szabályzatot!",
				spellcheck: false,
				toolbar: undefined,
				translate: "yes",
				editable: false
			}
		}
		// if (localStorage.getItem('role') == 'FEJLESZTO') {
		// 	this.role = true;
		// 	this.form.get('privacyPolicy').enable();
		//
		// }
	}

	getPolicy() {
		this.service.getPrivacyPolicy().subscribe((res) => {

			this.form.setValue({
				privacyPolicy: (res.privacyPolicy)
			});
			// if (localStorage.getItem('role') == 'FEJLESZTO') {
			// 	this.form.get('privacyPolicy').enable();
			// }
		});
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	edit() {
		this.service.newPrivacyPolicy(this.form.getRawValue()).subscribe((res) => {
			this.toastr.success('A szabályzatot sikeresen elmentette', '');
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}
}
