import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {AuthService} from '../../../core/auth/_services';
import {NgxPermissionsService} from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
	CropperDialogComponent,
	dataURLtoFile
} from '../cropper-dialog/cropper-dialog.component';
import * as jwt_decode from 'jwt-decode';
import {TranslateService} from '@ngx-translate/core';
import {TranslationService} from '../../../core/_base/layout';

const url = '/assets/media/avatar.png';
const styles = theme => ({
	'@global': {
		body: {
			backgroundColor: theme.background.default,
			color: theme.text.default,
			fontFamily: theme.typography.fontFamily,
			margin: '1em'
		}
	},
	cropping: {
		maxWidth: '400px',
		height: '300px'
	},
	icon: {
		marginEnd: '.25em'
	}
});

@Component({
	selector: 'kt-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	@ViewChild('imagee', null) imagee: ElementRef;
	form: FormGroup;
	form2: FormGroup;

	inviterId;
	eventId;
	url: string | ArrayBuffer = url;
	vanmar: boolean = false;
	userType;
	kivalasztott: boolean = false;
	file: File;

	needTutelary: boolean = false;

	image: string | File = null;
	openP: boolean = false;
	privacy;
	aszfCheck: boolean = false;
	minDate = moment()
		.subtract(110, 'years')
		.toDate();
	maxDate = moment()
		.subtract(13, 'years')
		.subtract(1, 'days')
		.toDate();

	constructor(
		private permissionsService: NgxPermissionsService,
		private auth: AuthService,
		private cdr: ChangeDetectorRef,
		private translationService: TranslationService,
		private router: Router,
		private toastr: ToastrService,
		private location: Location,
		private http: HttpClient,
		private route: ActivatedRoute,
		private fb: FormBuilder,
		private modal: NgbModal
	) {
		var blob = null;
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.onload = () => {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				this.url = reader.result;
			});
			reader.readAsDataURL(xhr.response);
		};
		xhr.send();

	}

	@HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
		e.preventDefault();
	}


	ngOnInit() {
		localStorage.clear();
		this.translationService.setLanguage(
			this.translationService.getSelectedLanguage()
		);
		// this.route.queryParamMap.subscribe(params => {
		// 	this.inviterId = params.get("inviterId");
		// 	this.eventId = params.get("eventId");
		// 	this.userType = params.get("userType");
		// 	this.location.replaceState(location.pathname.split("?")[0]);
		// });

		this.route.fragment.subscribe(fragment => {
			let decoded: any = atob(fragment);
			decoded = JSON.parse(
				decoded
					.replace(/\?(.*)/, '{$1}')
					.replace(/=/g, ':')
					.replace(/&/g, ',')
					.replace(/([,\{])(.*?):/g, '$1"$2":')
			);
			this.inviterId = decoded.inviterId;
			this.eventId = decoded.eventId;
			this.userType = decoded.userType;
		});

		this.form = this.fb.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			emailRe: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			password: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(8),
					Validators.maxLength(100),
					Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
				])
			],
			passwordRe: [
				'',
				Validators.compose([
					Validators.required,
					this.matchValues('password'),
					Validators.minLength(8),
					Validators.maxLength(100),
					Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)
				])
			],
			firstname: [
				'',
				[
					Validators.required,
					Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáéúőóűüöíA-Z_-]{3,15}$')
				]
			],
			lastname: [
				'',
				[
					Validators.required,
					Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáé úőóűüöíA-Z_-]{3,20}$')
				]
			],
			eventId: [''],
			userType: [''],
			tutelaryName: [''],
			birthDate: ['', Validators.required],
			birthPlace: ['', Validators.required],
			motherName: ['', Validators.required],
			privacyPolicy: ['', Validators.required]
		});

		this.form2 = this.fb.group({
			email: [
				'',
				Validators.compose([
					Validators.required,
					Validators.pattern(
						'^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'
					),
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			password: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100)
				])
			]
		});
		this.form.controls['privacyPolicy'].valueChanges.subscribe(value => {
			this.aszfCheck = value;
		});
		this.form.controls['birthDate'].valueChanges.subscribe(value => {
			let mdate = moment(value).format('YYYY-MM-DD');
			let yearThen = parseInt(mdate.substring(0, 4), 10);
			let monthThen = parseInt(mdate.substring(5, 7), 10);
			let dayThen = parseInt(mdate.substring(8, 10), 10);

			let today = new Date();
			let birthday = new Date(yearThen, monthThen - 1, dayThen);

			let differenceInMilisecond = today.valueOf() - birthday.valueOf();

			let year_age = Math.floor(differenceInMilisecond / 31536000000);
			let day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
			let month_age = Math.floor(day_age / 30);

			day_age = day_age % 30;

			if (year_age >= 13 && year_age <= 16) {
				if (year_age == 13) {
					if (month_age == 0) {
						if (day_age == 0) {
							this.needTutelary = false;
						}
						else{
							this.needTutelary = true;
						}
					}
				}
				if (year_age == 16) {
					if (day_age > 0 && month_age > 0) {
						this.needTutelary = false;
					}
				}
				else{
					this.needTutelary = true;
				}
			} else {
				this.needTutelary = false;
			}
		});
	}


	openPrivacy() {
		this.http.get<any>(baseUrl + 'privacyPolicy').subscribe(res => {
			this.openP = true;
			this.privacy = res.privacyPolicy;
		});
	}

	nincsFile() {
		return this.image == null;
	}

	back() {
		this.openP = false;
	}

	setLocale(lang) {
		this.translationService.setLanguage(lang);
	}
	 hasPasswordError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};
	checkTutelary(){
		if(this.needTutelary){
			if(!this.form.get('tutelaryName').value){
				return true
			}
			else{
				return false
			}
		}
	}

	passwor() {
		return (
			this.form.get('password').value != this.form.get('passwordRe').value
		);
	}

	emailValid() {
		return this.form.get('emailRe').value != this.form.get('email').value;
	}

	public matchValues(
		matchTo: string
	): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
			return !!control.parent &&
			!!control.parent.value &&
			control.value === control.parent.controls[matchTo].value
				? null
				: {isMatching: false};
		};
	}

	nemegyezik() {
		return (
			this.form.controls['password'].value ==
			this.form.controls['passwordRe'].value
		);
	}



	public hasError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};

	openCropModal(event) {
		const ref = this.modal.open(CropperDialogComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg'
		});
		ref.componentInstance.source = event;
		ref.result.then(result => {
			if (result) {
				this.url = result;
				this.image = dataURLtoFile(result, 'file.png');
			}
		});
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	login() {
		this.vanmar = true;
	}

	submit() {
		if (!this.image) {
			this.toastr.error('Kép megadása kötelező!');
			return;
		}
		const formData = new FormData();
		formData.append('email', this.form.controls['email'].value);
		formData.append('password', this.form.controls['password'].value);
		formData.append('eventId', this.eventId);
		formData.append('inviterId', this.inviterId);
		// birthDate:['', Validators.required],
		formData.append('motherName', this.form.controls['motherName'].value);

		formData.append('birthPlace', this.form.controls['birthPlace'].value);
		const birth = moment(this.form.controls['birthDate'].value).format(
			'YYYY-MM-DD'
		);
		formData.append('birthDate', birth);
		if(this.needTutelary){
			formData.append('tutelaryName', this.form.controls['tutelaryName'].value)
		}
		formData.append('firstName', this.form.controls['firstname'].value);
		formData.append('lastName', this.form.controls['lastname'].value);
		if (this.image) {
			formData.append('file', this.image);
		}
		if (this.userType == 1) {
			formData.append('roleType', 'ADMIN');
		}
		if (this.userType == 2) {
			formData.append('roleType', 'SUPERVISOR');
		}
		if (this.userType == 3) {
			formData.append('roleType', 'ALSUPERVISOR');
		}
		if (this.userType == 4) {
			formData.append('roleType', 'USER');
		}
		if (this.userType == 5) {
			formData.append('roleType', 'PENZTAROS');
		}
		return this.http
			.post<any>(baseUrl + 'users/registerUserFromUrl', formData, {
				observe: 'response'
			})
			.subscribe(
				res => {
					if (res.status == 200) {
						this.toastr.success('A regisztráció sikeres volt', '');
						this.router.navigate(['/auth/login']);
					} else {
						this.toastr.error('Hiba regisztráció során', '');
					}
				},
				error => {
					this.toastr.error(error.error.message, '', {
						timeOut: 3000
					});
				}
			);
	}

	submit2() {
		const emaile = this.form2.controls['email'].value;
		const password = this.form2.controls['password'].value;
		const formD = {
			email: emaile,
			password: password,
			eventId: this.eventId,
			inviterId: this.inviterId,
			roleType: this.userType
		};
		const LoginForm = {
			username: emaile,
			password: password
		};

		this.http
			.post<any>(baseUrl + 'users/alreadyMember', formD, {
				observe: 'response'
			})
			.subscribe(
				res => {
					this.toastr.success(
						'Sikeres bejelentkezés és meghívó aktiválás',
						''
					);
					this.auth.marciLogin(LoginForm).subscribe(res => {
						localStorage.setItem('token', res.token);
						localStorage.setItem('role', res.role);
						this.permissionsService.loadPermissions([res.role]);
						const decoded = this.getDecodedAccessToken(res.token);
						this.auth.setFirstname(decoded.firstname);
						this.auth.setLastname(decoded.lastname);
						this.auth.setUser(decoded.sub);
						localStorage.setItem('userId', decoded.userId);
						this.router.navigate(['/']);
					});
				},
				error => {
					this.toastr.error(error.error.message, '');
				}
			);
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}
}
