import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class RedemptionService {
	constructor(private http: HttpClient) {

	}

	getData(params) {
		return this.http.get<any>(baseUrl + 'users/usersCassaAll', {params: params});
	}

	getDatawithId(formdata) {
		return this.http.post<any>(baseUrl + 'users/userPassAndBracelets',formdata);
	}

	redemption(form){
		return this.http.post<any>(baseUrl +'redemption', form);
	}

	downloadPass(form){
		return this.http.post<any>(baseUrl+'users/loadUserPass',form);
	}
	downloadP(userId,EventId){
		return this.http.get<any>(baseUrl+`users/loadUserPass/${userId}/${EventId}`)
	}

	changeImage(userId,formdata): Observable<any>{
		return this.http.post<any>(baseUrl+"users/changeUserProfileImage/"+userId, formdata,{observe: 'response'});
	}
}
