import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, tap, map} from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router: Router, private toastr: ToastrService) {
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const authToken = localStorage.getItem('token');

		let authReq = req;
		if (
			!(

				req.url.startsWith('http://localhost:8080/login') ||
				req.url.startsWith('https://fradi.herokuapp.com/login') ||
				req.url.startsWith(
					'https://fradi.herokuapp.com/forgottenPassword'
				) ||
				(req.url.startsWith(
					'https://fradi.herokuapp.com/privacyPolicy'
					) &&
					req.method === 'GET') ||
				(req.url.startsWith('https://fradi.herokuapp.com/aboutUs') &&
					req.method === 'GET')
			)
		) {
			const parsedToken = jwt_decode(authToken);
			if (parsedToken.exp < new Date().setMilliseconds(0) / 1000) {
				localStorage.setItem('redirectUrl', location.pathname);
				this.router.navigateByUrl('auth/login', {
					state: {reason: 'SESSIONTIMEOUT'}
				});

				return throwError('expired token');
			}
			authReq = req.clone({
				setHeaders: {
					Authorization: `Bearer ${authToken}`
				}
			});
		}
		return next.handle(authReq).pipe(
			// retry(1),
			map(something => {
				return something;
			}),
			catchError((error: HttpErrorResponse) => {
				if (error.status == 401) {
					return throwError(error);
				}
				if (error.status == 500) {
					if (error.error.constructor.name == 'ArrayBuffer') {
						return throwError(error);

					}
						this.toastr.error(error.error.message, '', {timeOut: 3000});
				} else {
					return throwError(error);
				}
			})
		);
	}
}
