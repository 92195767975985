import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../url.model';

@Injectable({providedIn: 'root'})
export class UsersService {
	constructor(private http: HttpClient) {
	}


	getUsers(params): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/usersAll', {params: params});
	}

	getMyUser(): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/getMyUsers');
	}

//todo másik végpontot átkötni
	getMyUser2(): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/getMyUsers');
	}

	newGlobalSearch(params): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/searchOnEvent', {params: params});
	}

	globalSearch(params): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/globalSearch', {params: params});
	}

	getEvents(): Observable<any>{
		return this.http.get<any>(baseUrl+"users/myEvents")
	}

	newUser(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/createUser', formdata, {observe: 'response'});
	}

	deleteUser(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'users/delete/' + id);
	}


	putUser(id, formdata): Observable<any> {
		return this.http.put<any>(baseUrl + 'users/modifyUser/' + id, formdata, {observe: 'response'});
	}

	getUser(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/' + id);
	}

	getAvailableRoles(): Observable<any> {
		return this.http.get<any>(baseUrl + 'roles/avaibleRoles', {observe: 'response'});
	}


	getAvailableParkingSlots(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/avaibleParkingSlots');
	}

	getAviblePasses(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/avaiblePasses');
	}

	getCurrentuserAvalable() {
		return this.http.get<any>(baseUrl + 'users/currentUser/avaiblePasses');
	}

	getAvailablebracelets(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/avaibleBracelets');
	}

	getCurrentuserAvalableBracelet() {
		return this.http.get<any>(baseUrl + 'users/currentUser/avaibleBracelets');
	}

	modifyBraceletLimitToSupervisor(formdata) {
		return this.http.post<any>(baseUrl + 'users/braceletLimits', formdata, {observe: 'response'});
	}

	modifyPassLimitToSupervisor(formdata) {
		return this.http.post<any>(baseUrl + 'users/passLimits', formdata, {observe: 'response'});
	}


	modifyParkingLimitToSupervisor(formdata) {
		return this.http.post<any>(baseUrl + 'users/parkingLimits', formdata, {observe: 'response'});
	}

	getPassLimitToSupervisor(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/passLimits');
	}

	getBraceletLimitToSupervisor(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/braceletLimits');
	}

	getParkingLimitToSupervisor(id) {
		return this.http.get<any>(baseUrl + 'users/' + id + '/parkingLimits');
	}
}
