import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ArmbandService {
	constructor(private http: HttpClient) {

	}

	getArmbands(params) {
		return this.http.get<any>(baseUrl + 'bracelets/braceletAll', {params: params});
	}

	getArmband(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'bracelets/' + id);
	}

	newArmband(formdata) {
		return this.http.post<any>(baseUrl + 'bracelets', formdata, {observe: 'response'});
	}

	deleteBracelet(id) : Observable<any>{
		return this.http.delete<any>(baseUrl+'bracelets/'+id, {observe:'response'});
	}
	deleteBraceletwithPassword(id,password): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {
				password: password
			},
		};
		return this.http.delete<any>(baseUrl+'bracelets/'+id,  options);
	}
	modifyEvent(id,formdata): Observable<any>{
		return this.http.put<any>(baseUrl+'bracelets/'+id,formdata,{observe:'response'})
	}
}
