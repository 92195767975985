import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-play-helper',

	templateUrl: './play-helper.component.html',
	styleUrls: ['./play-helper.component.scss']
})
export class PlayHelperComponent implements OnInit {
	@Input() public link;
	play;
	player;
	url;
	safeUrl;
	baseUrl: string = 'https://www.youtube.com/embed/';

	constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {
	}

	ngOnInit() {
		this.vagas();
		this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.player);
	}

	onClose(): void {
		this.activeModal.close();
	}

	vagas() {
		this.play = this.link.split('=');
		this.player = this.play[1];
	}
}
