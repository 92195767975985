import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InformationService} from '../information.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'kt-information-data',
	templateUrl: './information-data.component.html',
	styleUrls: ['./information-data.component.scss']
})
export class InformationDataComponent implements OnInit {
	id;
	data;

	constructor(private cdr: ChangeDetectorRef,private toastr: ToastrService,private route: ActivatedRoute, private informationService: InformationService) {
	}

	ngOnInit() {
		this.id = this.route.snapshot.paramMap.get('id');
		this.getData();
	}

	getData(){
		this.informationService.getEventsInformation(this.id).subscribe((res) => {
			this.data = res;
		},error => {
			this.toastr.error(error.error.message);
		});
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}
