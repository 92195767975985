import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../url.model';

@Injectable({providedIn: 'root'})
export class ProfileService {
	constructor(private http: HttpClient) {
	}

	getMyData(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'users/' + id);
	}

	changePassword(formdata) {
		return this.http.post<any>(baseUrl + 'users/changePassword', formdata);
	}

	saveData(formdata) {
		return this.http.post<any>(baseUrl + 'users/me', formdata);
	}

	me() {
		return this.http.get<any>(baseUrl + 'users/me');
	}

	deleteProfil(formdata){
		return this.http.post<any>(baseUrl+'users/deleteMyself',formdata);
	}
}
