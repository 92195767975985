import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {EventAttService} from './event-att.service';
import {BehaviorSubject} from 'rxjs';
import {Section} from '../../invited/activate-modal/activate-modal.component';
import {EventsService} from '../../events/events.service';
import {InvitedService} from '../../invited/invited.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatListOption, MatSelectionList} from '@angular/material';
import {FormBuilder} from '@angular/forms';

@Component({
	selector: 'kt-event-att',
	templateUrl: './event-att.component.html',
	styleUrls: ['./event-att.component.scss']
})
export class EventAttComponent implements OnInit {
	@Input() public userId;
	selectedEventId;
	@ViewChild(MatSelectionList, null) selectionList: MatSelectionList;
	available$ = new BehaviorSubject([]);
	passes: Section[];
	bracelets: Section[];
	form = this.fb.group({
		event: ['']
	});
	selectedOptions = [];
	selectedOption;
	activateButton: boolean = false;
	constructor(private fb: FormBuilder,private iservice: InvitedService, private eventService: EventsService, private service: EventAttService, public activeModal: NgbActiveModal, private toastr: ToastrService) {
	}


	changeEvent(event) {
		if (event) {
			this.selectedEventId = event;
			this.post();
			this.activateButton = true;
		} else {
			this.activateButton = false;
		}
	}




	ngOnInit() {
		this.selectionList.selectedOptions = new SelectionModel<MatListOption>(false);
		this.getAttachableEvents();
	}

	post() {
		const formData = {
			userId: this.userId,
			eventId: this.selectedEventId
		};
		this.iservice.attachable(formData).subscribe((res) => {
			this.passes = res.body.passes;
			this.bracelets = res.body.bracelets;

		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	submit() {
		const formd = {
			userId: this.userId,
			itemId: this.selectedOption[0].id,
			eventId: this.form.controls['event'].value,
			itemType: this.selectedOption[0].type
		};
		this.service.attachUserToEvent(formd).subscribe((res) => {
				this.toastr.success('Sikeresen hozzáadta az eseményhez', '');
				this.onClose();
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	getAttachableEvents() {
		this.service.attachableEvents(this.userId).subscribe((res) => {
			this.available$.next(res);
		});
	}

	onNgModelChange(event) {
		this.selectedOption = event;
	}

	onClose(): void {
		this.activeModal.close(true);
	}
}
