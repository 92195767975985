import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {EventsService} from './events.service';
import {NewEventComponent} from './new-event/new-event.component';
import {FormBuilder, FormControl} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {catchError, debounceTime, map} from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import {MyLinksComponent} from '../my-links/my-links.component';
import {ScopeMailComponent} from './scope-mail/scope-mail.component';

@Component({
	selector: 'kt-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class EventsComponent implements OnInit {
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['username', 'startDate', 'endDate', 'actions'];
	search = new FormControl();
	private subscriptions: Subscription[] = [];
	pageSize = 50;
	show = false;
	page = 1;
	nev = '';
	collection: number;
	rendezesirany = 'asc';
	role: boolean = true;

	constructor(
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private router: Router,
		private toastr: ToastrService,
		private confirmationDialogService: ConfirmationDialog,
		private store: Store<AppState>,
		private eventService: EventsService,
		private ngxPermissionService: NgxPermissionsService) {
	}

	textareaEnterPressed($event: KeyboardEvent) {
		$event.preventDefault();
		$event.stopPropagation();
		this.getData();
	}

	ngOnInit() {
		const penztaros = this.ngxPermissionService.getPermissions().hasOwnProperty('PENZTAROS');
		if (penztaros) {
			this.router.navigateByUrl('/redemption');
		}
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
		this.getData();
		this.show = this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO');
		this.cdr.detectChanges();

	}

	szukites() {
		this.getData();
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	newEvent(item?) {
		const modalRef = this.modalService.open(NewEventComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = item;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	refreshVoucher(event) {
		const userId = localStorage.getItem('userId');
		const form = {
			userId: userId,
			eventId: event.id
		};
		this.eventService.refreshVoucher(form).subscribe((res) => {
				this.toastr.success('A voucher-t kiküldtük az email címére', '');
			}, error => {
				this.toastr.error(error.error.message, '');
			}
		);
	}

	downloadVoucher(event) {
		const userId = localStorage.getItem('userId');
		this.eventService.downloadVoucher(event.id, userId).subscribe((res) => {
			var link = document.createElement("a");
			let file = new Blob([res], {type: 'application/pdf'});
			var fileUrl = URL.createObjectURL(file);
			link.setAttribute("href", fileUrl);
			link.setAttribute("download", 'Voucher'+userId+'.pdf');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});
	}

	invitingVoucher(event) {
		const user = localStorage.getItem('userId');
		this.eventService.invitingVoucher(event.id, user).subscribe((res) => {
			var link = document.createElement("a");
			let file = new Blob([res], {type: 'application/pdf'});
			var fileUrl = URL.createObjectURL(file);
			link.setAttribute("href", fileUrl);
			link.setAttribute("download", 'invitingVoucher'+user+'.pdf');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});
	}

	downloadParking(event) {
		const userId = localStorage.getItem('userId');
		this.eventService.downloadParking(event.id, userId).subscribe(res => {
			var link = document.createElement("a");
			let file = new Blob([res], {type: 'application/pdf'});
			var fileUrl = URL.createObjectURL(file);
			link.setAttribute("href", fileUrl);
			link.setAttribute("download", 'parking'+userId+'.pdf');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});

	}

	sortData(event) {
		this.nev = event.active;
		this.rendezesirany = event.direction;
		this.getData();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('rendezesirany', this.rendezesirany);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.eventService.getEvents(params).subscribe((res) => {
				this.collection = res.osszes;
				this.dataSource = res.elemek;
			}
		);
	}

	MailSettings(event) {
		const modalRef = this.modalService.open(ScopeMailComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = event.id;
		modalRef.componentInstance.name = event.name;
		modalRef.result.then(() => {
		}).catch(() => {
		});
	}

	getMyLinks(event) {
		const modalRef = this.modalService.open(MyLinksComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = event.id;
		modalRef.result.then(() => {
		}).catch(() => {
		});
	}


	deleteUser(event) {
		this.confirmationDialogService
			.confirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			.then((confirmed => {
				if (confirmed) {
					this.eventService.deleteEvent(event.id).subscribe((res) => {
						if (res.status == 200) {
							this.toastr.success('Sikeresesn törölte az eseményt', '');
							this.getData();
						} else {
							this.toastr.error('Hiba törléskor', '');
						}
					});
				} else {
					this.toastr.warning('Törlés elutasítva', '');
					this.getData();
				}
			}));


	}
}
