import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import {HttpParams} from '@angular/common/http';
import {ArmbandService} from './armband.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewandeditArmbandComponent} from './newandedit-armband/newandedit-armband.component';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {ToastrService} from 'ngx-toastr';
import {debounceTime} from 'rxjs/operators';

@Component({
	selector: 'kt-armband',
	templateUrl: './armband.component.html',
	styleUrls: ['./armband.component.scss']
})
export class ArmbandComponent implements OnInit {
	search = new FormControl();
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['#','name', 'identity', 'actions'];
	pageSize = 50;
	page = 1;
	collection: number;
	rendezesirany = 'asc';
	colorCode;

	constructor(private toastr: ToastrService, private confirmationDialogService: ConfirmationDialog, private modalService: NgbModal, private service: ArmbandService, private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
		this.getData();
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}
	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('rendezesirany', this.rendezesirany);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.service.getArmbands(params).subscribe((res) => {
				this.collection = res.osszes;
				this.dataSource = res.elemek;
			}
		);
	}


	newArmband(item?) {
		const modalRef = this.modalService.open(NewandeditArmbandComponent, {
			size: 'lg',
			backdrop  : 'static',
			keyboard  : false
		});
		modalRef.componentInstance.id = item;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});

	}

	deleteArmband(id) {
		this.confirmationDialogService
			.passwordconfirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			.then(result => {
				if (result[0].result) {
					this.service.deleteBraceletwithPassword(id, result[0].password).subscribe((res) => {
						this.toastr.success('Sikeresen törölte a karszalagot-t', '');
						this.getData();
					},error => {
						this.toastr.error(error.error.message,'');
						this.getData();
					});
				} else {
					this.toastr.warning('Törlés elutasítva', '');
					this.getData();
				}
			// 	if (confirmed) {
			// 		this.service.deleteBracelet(element).subscribe((res) => {
			// 			if (res.status == 200) {
			// 				this.toastr.success('Sikeresen törölte a karszalagot', '');
			// 				this.getData();
			// 			} else {
			// 				this.toastr.error('Hiba törléskor', '');
			// 			}
			// 		},error => {
			// 			this.toastr.error(error.error.message,'');
			// 		});
			// 	} else {
			// 		this.toastr.warning('Törlés elutasítva', '');
			// 		this.getData();
			// 	}
			// }));
			});

	}
}
