import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {baseUrl} from '../../../url.model';

@Injectable({providedIn: 'root'})
export class CheckDataService {
	constructor(private http: HttpClient) {
	}

	// putUser(formdata): Observable<any> {
	// 	return this.http.put<any>(baseUrl + 'users/upgradeUser' , formdata, {observe: 'response'});
	// }
	putUser(formdata): Observable<any> {
		return this.http.put<any>(baseUrl + 'users/upgradeUser' , formdata, {observe: 'response'});
	}

	downloadUserPass(userID,dataid):Observable<any>{
		// window.open(baseUrl + `users/loadUserPass/${this.userID}/${dataid}`, '_blank');
		// window.open(baseUrl + 'events/eventExport/' + this.eventId);
		return this.http.get(baseUrl + `users/loadUserPass/${userID}/${dataid}`,{responseType:'arraybuffer'});
	}
}
