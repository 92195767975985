import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';

@Injectable({providedIn: 'root'})
export class MailTemplateService {

	constructor(private http: HttpClient) {

	}
	getTemplate() {
		return this.http.get<any>(baseUrl + 'emailTemplates');
	}

	putTemplate(id,form){
		return this.http.put<any>(baseUrl+'emailTemplates/'+id,form,{observe: 'response'});
	}
}
