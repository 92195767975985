import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InformationService {

	constructor(private http: HttpClient) {

	}

	getAllEvents() {
		return this.http.get<any>(baseUrl + 'statistics');
	}

	getEventsInformation(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'statistics/events/' + id);
	}
}
