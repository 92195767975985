import {Component,  OnInit} from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatTableDataSource} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {UserPermisService} from './user-permis.service';
import {HttpParams} from '@angular/common/http';
import {VouchersService} from '../../vouchers/vouchers.service';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'kt-user-permis',
	templateUrl: './user-permis.component.html',
	styleUrls: ['./user-permis.component.scss']
})
export class UserPermisComponent implements OnInit {
	id;
	subCategoryList = new MatTableDataSource<any>();
	eventList;
	selectedRow;
	voucherList;
	displayedColumns: string[] = [
		'eventname',
		'vouchername',
		'functions'];


	constructor(private toastr: ToastrService,private modalService: NgbModal, private service: UserPermisService, private route: ActivatedRoute, private voucherService: VouchersService) {
	}


	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			this.id = params.get('id');
			this.service.getAttachedVouchers(this.id).subscribe((res) => {
				this.subCategoryList.data = res;
			});
		});
		this.getVoucher();
		this.getEvents();
	}


	getVoucher() {
		this.voucherService.getVouchers(null).subscribe((res) => {

			this.voucherList = res.elemek;
		});
	}

	getEvents() {
		let params = new HttpParams();
		params = params.append('id', this.id);
		this.service.getUserEvents(params).subscribe((res) => {
			this.eventList = res;
		});
	}

	addSubcateg() {


		if (this.subCategoryList.data) {
			this.subCategoryList.data.push({
				eventId: '',
				voucherId: '',
			});
		} else {
			this.subCategoryList.data = [];
		}
		this.subCategoryList.data = this.subCategoryList.data;
	}

	removeSubCateg(item) {

		const index = this.subCategoryList.data.indexOf(item.id);
		this.subCategoryList.data.splice(item,1);
		this.subCategoryList._updateChangeSubscription();

	}

	mentes() {
		const data = {
			userId: this.id,
			voucherOnEvents: this.subCategoryList.data.map(row => {
				delete row.hovered;
				return row;
			})
		};
		this.service.saveVoucherstoEvent(data).subscribe((res) => {
			if(res.status==200){
				this.toastr.success('A jogosultságot/jogosultságokat sikeresen beállította','');
			}
			else{
				this.toastr.error('beállítási hiba','');
			}
		},error => {
			this.toastr.error(error.error.message,'');
		});
	}

	changeVoucher(event, i) {
		this.subCategoryList.data[i].eventId = event.id;
	}

	changeEvent(event, i) {
		this.subCategoryList.data[i].voucherId = event.id;
	}
}
