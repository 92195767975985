import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal,  NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {EventsService} from '../events.service';
import moment from 'moment';
import {HttpParams} from '@angular/common/http';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatTableDataSource} from '@angular/material';
import {BehaviorSubject, Observable} from 'rxjs';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {delay, map, startWith} from 'rxjs/operators';
import {Events} from '../../users/new/new.component';
import {MyLinksComponent} from '../../my-links/my-links.component';
import {Router} from '@angular/router';

@Component({
	selector: 'kt-new-event',
	templateUrl: './new-event.component.html',
	styleUrls: ['./new-event.component.scss']
})
export class NewEventComponent implements OnInit {
	@Input() public id;
	form = this.fb.group({
		name: ['', Validators.required],
		from: ['', Validators.required],
		to: ['', Validators.required],
		startTime: ['',Validators.required],
		endTime: ['',Validators.required]
	});
	visible = true;
	fruitCtrl = new FormControl();
	filteredColors: Observable<any[]>;
	selectedColors: string[] = [];

	allColors =  new BehaviorSubject([]);
	@ViewChild('fruitInput', {static: false}) fruitInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

	//ez a táblázatos forma elemei
	colors = new MatTableDataSource<Events>();
	displayedColumns: string[] = ['checked', 'name'];
	eventColorDetail: any = {};
	time: NgbTimeStruct = {hour: 13, minute: 30, second: 0};

	add(event: MatChipInputEvent): void {
		if (!this.matAutocomplete.isOpen) {
			const input = event.input;
			const value = event.value;

			// Add our fruit
			if ((value || '').trim()) {
				this.selectedColors.push(value.trim());
			}

			// Reset the input value
			if (input) {
				input.value = '';
			}

			this.fruitCtrl.setValue(null);
		}
	}

	check($event, element) {
		if (!this.eventColorDetail.colors) {
			this.eventColorDetail.colors = [];
		}
		if ($event.checked) {
			this.eventColorDetail.colors.push(element.id);
		} else {
			this.eventColorDetail.colors = this.eventColorDetail.colors.filter(event => {
				return element.id !== event;
			});
		}
	}

	isSelected(row) {
		return this.eventColorDetail.colors && this.eventColorDetail.colors.find(x => {
			return x === row.id;
		});
	}

	remove(fruit: string): void {
		const index = this.selectedColors.indexOf(fruit);

		if (index >= 0) {
			this.selectedColors.splice(index, 1);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedColors.push(event.option.viewValue);
		this.fruitInput.nativeElement.value = '';
		this.fruitCtrl.setValue(null);
	}

	private _filter(value: string): any[] {
		const filterValue = value.toLowerCase();
		return this.allColors.value.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
	}

	constructor(private router:Router,private modalService: NgbModal, private eventService: EventsService, public activeModal: NgbActiveModal, private toastr: ToastrService, private fb: FormBuilder) {
		this.filteredColors = this.fruitCtrl.valueChanges.pipe(
			startWith(null),
			map((fruit: any | null) => fruit ? this._filter(fruit) : this.allColors.value.slice()));
	}

	ngOnInit() {
		if (this.id) {
			this.eventService.getEvent(this.id).subscribe(resp => {
				this.form.setValue({
					name: resp.name,
					from: resp.from,
					to: resp.to,
					colors: resp.colors,
					startTime: '11:10',
					endTime: '22:00'
				});
				this.eventColorDetail.colors = resp.colorIds;
			});
		}

	}



	getMyLinks(){
		const modalRef = this.modalService.open(MyLinksComponent, {
			size: 'lg',
			backdrop  : 'static',
			keyboard  : false
		});
		this.activeModal.close();
		modalRef.componentInstance.id = this.id;
		modalRef.result.then(() => {
		}).catch(() => {
		});
	}


	save() {
		const searchData = this.form.value;
		let params = new HttpParams();
		if (searchData.name) {
			params = params.append('name', searchData.name);
		}

		const start = moment(searchData.from).format('YYYY-MM-DD');
		const end = moment(searchData.to).format('YYYY-MM-DD');
		this.form.controls['from'].setValue(start);
		this.form.controls['to'].setValue(end);
		this.eventService.newEvent(this.form.getRawValue()).subscribe((res) => {
					if (res) {
						this.toastr.success('Az eseményt sikeresen létrehozta', '');
						this.activeModal.close(true);

						this.router.navigate(['/events/'+res.id]);
					} else {
						this.toastr.error('Hiba létrehozás közben', 'Hiba');
					}
				}, (error) => {
					this.toastr.error(error.error.message, 'Hiba');
				});
	}
}
