import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {VouchersService} from './vouchers.service';
import {NewVoucherComponent} from './new-voucher/new-voucher.component';
import {baseUrl} from '../url.model';

@Component({
  selector: 'kt-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {
	search = new FormControl();
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['name', 'description', 'actions'];
	pageSize = 50;
	page = 1;
	nev = '';
	collection: number;
	rendezesirany = 'asc';

	constructor(private toastr: ToastrService,private confirmationDialogService: ConfirmationDialog,private modalService: NgbModal,private service: VouchersService,private changeDetector: ChangeDetectorRef) {
	}

  ngOnInit() {
	  this.search.valueChanges.pipe(debounceTime(500)).subscribe(str=>
		  this.getData());
	  this.getData();
  }
	ngAfterViewChecked(){
		this.changeDetector.detectChanges();
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('rendezesirany', this.rendezesirany);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.service.getVouchers(params).subscribe((res)=>{
			this.collection = res.osszes;
			this.dataSource = res.elemek;
		});
	}

	download(id){
		this.service.downloadVoucher(id).subscribe((res) => {
			var link = document.createElement("a");
			let file = new Blob([res], {type: 'application/pdf'});
			var fileUrl = URL.createObjectURL(file);
			link.setAttribute("href", fileUrl);
			link.setAttribute("download", 'voucher'+id+'.pdf');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});
	}

	editVoucher(colors?){
		const modalRef = this.modalService.open(NewVoucherComponent, {
			size: 'lg',
			backdrop  : 'static',
			keyboard  : false
		});
		modalRef.componentInstance.id = colors;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	deleteVoucher(id){
		this.confirmationDialogService
			.passwordconfirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			.then(result => {
				if (result[0].result) {
					this.service.deleteVoucherwithPassword(id, result[0].password).subscribe((res) => {
						this.toastr.success('Sikeresen törölte a vouchert-t', '');
						this.getData();
					},error => {
						this.toastr.error(error.error.message,'');
						this.getData();
					});
				} else {
					this.toastr.warning('Törlés elutasítva', '');
					this.getData();
				}
			});

			// .then((confirmed => {
			// 	if (confirmed) {
			// 		this.service.deleteVoucher(id).subscribe((res)=>{
			// 			if(res.status == 200){
			// 				this.toastr.success('Sikeresesn törölte a vouchert', '');
			// 				this.getData();
			// 			}
			// 			else{
			// 				this.toastr.error('Hiba törléskor','');
			// 			}
			// 		});
			// 	} else {
			// 		this.toastr.warning('Törlés elutasítva', '');
			// 		this.getData();
			// 	}
			// }));

	}
}
