import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../../url.model';

@Injectable({providedIn: 'root'})
export class UserPermisService {
	constructor(private http: HttpClient) {
	}

	getUserEvents(params) {
		return this.http.get<any>(baseUrl + 'users/getUserEvents', {params: params});
	}


	saveVoucherstoEvent(data) {
		return this.http.post<any>(baseUrl + 'users/attachVouchersToEvent', data,{observe: 'response'});
	}


	getAttachedVouchers(id){
		return this.http.get<any>(baseUrl + `users/${id}/loadAttachedVouchers`);
}
}
