import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VouchersService{
	constructor(private http: HttpClient) {

	}

	getVouchers(params){
		return this.http.get<any>(baseUrl + 'vouchers/voucherAll',{params: params});
	}
	newVoucher(formdata) : Observable<any>{
		return this.http.post<any>(baseUrl + 'vouchers',formdata,{observe: 'response'});
	}

	downloadVoucher(id):Observable<any>{
		return this.http.get(baseUrl+`vouchers/loadVoucherSample/${id}`,{responseType: 'arraybuffer'});
	}
	getVoucher(id): Observable<any>{
		return this.http.get<any>(baseUrl+'vouchers/'+id);
	}

	modifyVoucher(id:number, formdata:any){
		return this.http.put<any>(baseUrl + 'vouchers/'+id, formdata,{observe: 'response'});
	}
	deleteVoucherwithPassword(id,password): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {
				password: password
			},
		};
		return this.http.delete<any>(baseUrl +'vouchers/'+id, options);
	}
	deleteVoucher(id) : Observable<any>{
		return this.http.delete<any>(baseUrl +'vouchers/'+id,{observe: 'response'});
	}
}
