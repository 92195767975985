import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'heroSearch' })
export class HeroFilterPipe implements PipeTransform {
	public transform(heroes: any[], searchText: any): any {
		if (searchText == null || heroes == null) {
			return heroes;
		}
		return heroes.filter(hero => hero.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
	}
}
