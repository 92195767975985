// Magyar
export const locale = {
	lang: 'en',
	data: {
		REGISTER: {
			TITLE: 'Registration',
			ALREADY: 'I already have an account!',
			INFOTEXT: 'A beírt adatokat kizárólag\n' +
				'                              az\n' +
				'                              adott szolgáltatáshoz használjuk fel, bizalmasan kezeljük,\n' +
				'                              és\n' +
				'                              nem adjuk ki! A Regisztráció gombra kattintva elfogadod\n' +
				'                              Felhasználási feltételeinket, Adatkezelési szabályzatunkat\n' +
				'                              és a\n' +
				'                              cookie-k használatára vonatkozó szabályzatunkat.',
			REQUIRED: 'A csillagos mezők kitöltése kötelező.',
			PERSONALDATA: 'Personal data:',
			FIRSTNAME: 'First Name',
			LASTNAME: 'Last Name',
			FIRSTNAMEERROR: 'A\n' +
				'                                vezetéknév\n' +
				'                                nem tartalmazhat speciális\n' +
				'                                karaktereket, csak kötőjelet, és minimum 3 és maximum 15\n' +
				'                                karakter hosszúságú',
			LASTNAMEERROR: 'A\n' +
				'                                keresztnév\n' +
				'                                nem tartalmazhat speciális\n' +
				'                                karaktereket, csak kötőjelet, és minimum 3 és maximum 20\n' +
				'                                karakter hosszúságú!',
			MOTHERNAME: 'Mothers name',
			BIRTHPLACE: 'Birthplace',
			BIRTHDATE: 'Date of Birth',
			CLICKHEREPLACEHOLDER: 'Please click here to set your date of birth',
			DATATOACCESS: 'Login required:',
			EMAIL: 'E-mail address',
			EMAILRE: 'E-mail address (Confirm)',
			EMAILNOTMATCH: 'A két megadott e-mail nem egyezik!',
			EMAILERROR: 'Az email cím\n' +
				'                                nem\n' +
				'                                tartalmazhat ékezetet és nagybetűt, minimum 3 és maximum\n' +
				'                                15\n' +
				'                                karakter hosszú lehet!\n' +
				'                                Kérem valós email címet adjon meg!',
			PASSWORD: 'Password ',
			PASSWORDRE: 'Password (Confirm)',
			PASSWORDNOTMATCH: 'A két megadott jelszó nem egyezik!',
			PASSWORDSTRENGTH: 'A jelszó minimum 8 karakter hosszú, tartalmaznia kell kis és nagybetűt, valamint számot.',
			REQUIREDIMAGE: 'Image to be uploaded (required *):',
			IMAGETOOLTIP: 'Please select the image you want to upload!',
			//I declare that I have read and understood the Data Privacy Policy. You can read the Data Privacy Policy by clicking HERE.”
			PRIVACYPOLICYFIRST: 'I declare that I have read and understood the Data Privacy Policy.',
			PRIVACYPOLICYSECOND: 'You can read the Data Privacy Policy by clicking HERE.',
			REGISTRATION: 'Register',
			PLEASELOGIN: 'Please login with your username and password:',
			ALREADYEMAIL: 'E-mail address',
			ALREADYEMAILERROR: 'Az email cím\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tnem\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\ttartalmazhat ékezetet és nagybetűt, minimum 3 és maximum\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t15\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tkarakter hosszú lehet!\n' +
				'\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tKérem valós email címet adjon meg!',
			LOGIN: 'Login',
			BACK: 'Back'
		},
	}
};
