import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {LoginpopupService} from '../loginpopup.service';

@Component({
	selector: 'kt-login-about-us',
	templateUrl: './login-about-us.component.html',
	styleUrls: ['./login-about-us.component.scss']
})
export class LoginAboutUsComponent implements OnInit {
	data;

	constructor(private service: LoginpopupService, public dialogRef: MatDialogRef<LoginAboutUsComponent>) {
	}

	ngOnInit() {
		this.service.getAboutUs().subscribe((res) => {
			this.data = res.aboutUs;
		});
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
