import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../../core/reducers';
import {PassesService} from './passes.service';
import {HttpParams} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material';
import {NewandeditPassComponent} from './newandedit-pass/newandedit-pass.component';
import {debounceTime} from 'rxjs/operators';
import FileSaver from 'file-saver';
import {DomSanitizer} from '@angular/platform-browser';
import {baseUrl} from '../url.model';

@Component({
	selector: 'kt-passes',
	templateUrl: './passes.component.html',
	styleUrls: ['./passes.component.scss']
})
export class PassesComponent implements OnInit {
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['name', 'color', 'information', 'actions'];
	search = new FormControl();
	pageSize = 10;
	page = 1;
	nev = '';
	collection: number;
	rendezesirany = 'asc';
	fileUrl;
	constructor(
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private toastr: ToastrService,private sanitizer: DomSanitizer,
		private confirmationDialogService: ConfirmationDialog,
		private store: Store<AppState>,
		private passService: PassesService) {
	}

	ngOnInit() {
		this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
			this.getData());
		this.getData();
	}


	newPass(item?) {

		const modalRef = this.modalService.open(NewandeditPassComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = item;

		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	getData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
		if (this.search.value && this.search.value.length > 2) {
			params = params.append('szoveg', this.search.value);
		}
		this.passService.getPasses(params).subscribe((res) => {
				this.collection = res.osszes;
				this.dataSource = res.elemek;
			}
		);
	}


	samplePass(id) {
		const userId = localStorage.getItem('userId');

		this.passService.downloadPass(id,userId).subscribe((res) => {
			var link = document.createElement("a");
			let file = new File([res], 'pass.pdf',{ type: 'application/pdf'  },);
			var fileURL = URL.createObjectURL(file);
			link.setAttribute("href", fileURL);
			link.setAttribute("download", 'pass'+userId+'.pdf');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});
	}


	deletePass(id) {
		this.confirmationDialogService
			.passwordconfirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			// .confirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			.then(result => {
				if (result[0].result) {
					this.passService.deletePasswithPassword(id, result[0].password).subscribe((res) => {
						this.toastr.success('Sikeresen törölte a pass-t', '');
						this.getData();
					}, error => {
						this.toastr.error(error.error.message, '');
						this.getData();
					});
				} else {
					this.toastr.warning('Törlés elutasítva', '');
					this.getData();
				}
				// this.passService.deletePass(id).subscribe((res)=>{
				// 	if(res.status == 200){
				// 		this.toastr.success('Sikeresen törölte a pass-t', '');
				// 		this.getData();
				// 	}
				// 	else{
				// 		this.toastr.error('Hiba törléskor','');
				// 	}
				// });
				// } else {
				// 	this.toastr.warning('Törlés elutasítva', '');
				// 	this.getData();
				// }
			});

	}

}
