import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {EventsService} from '../events.service';
import {PassesService} from '../../passes/passes.service';
import {HttpParams} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material';
import {Events} from '../../users/new/new.component';
import {MyLinksComponent} from '../../my-links/my-links.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ArmbandService} from '../../armband/armband.service';
import {ToastrService} from 'ngx-toastr';
import moment from 'moment';
import {baseUrl} from '../../url.model';
import {ParkingService} from '../../parking/parking.service';

@Component({
	selector: 'kt-events-data',
	templateUrl: './events-data.component.html',
	styleUrls: ['./events-data.component.scss']
})
export class EventsDataComponent implements OnInit {
	eventId;
	passes = new MatTableDataSource<Events>();
	armbands = new MatTableDataSource<any>();
	parkings = new MatTableDataSource<any>();
	collectionPasses: number;
	collectionParkings: number;
	collectionArmbands: number;
	displayedColumns = ['checked', 'name'];
	eventPasses: any = {};
	eventArmbands: any = {};
	eventParkings: any = {};
	form = this.fb.group({
		name: ['', Validators.required],
		from: ['', Validators.required],
		to: ['', Validators.required],
		passes: [''],
		bracelets: [''],
		startTime: ['', Validators.required],
		endTime: ['', Validators.required],
		parkingIds: ['']
	});

	constructor(
		private cdr: ChangeDetectorRef,
		private parkingService: ParkingService,
		private toastr: ToastrService,
		private armbandService: ArmbandService,
		private modalService: NgbModal,
		private passService: PassesService,
		private eventService: EventsService,
		private fb: FormBuilder,
		private route: ActivatedRoute
	) {
	}

	ngOnInit() {
		this.eventId = this.route.snapshot.paramMap.get('id');
		this.getPasses();
		this.getArmbands();
		this.getParkings();
		if (this.eventId) {
			this.eventService.getEvent(this.eventId).subscribe(resp => {
				this.form.setValue({
					name: resp.name,
					from: resp.from,
					to: resp.to,
					passes: resp.passes,
					bracelets: resp.bracelets,
					startTime: resp.startTime,
					parkingIds: resp.parkingSlots,
					endTime: resp.endTime
				});
				this.eventPasses.passes = resp.passIds;
				this.eventArmbands.armbands = resp.braceletIds;
				this.eventParkings.parkings = resp.parkingSlotsIds;
			});
		}
		this.cdr.detectChanges();
	}

	isSelectedPass(row) {
		return (
			this.eventPasses.passes &&
			this.eventPasses.passes.find(x => {
				return x === row.id;
			})
		);
	}

	isSelectedArmbands(row) {
		return (
			this.eventArmbands.armbands &&
			this.eventArmbands.armbands.find(x => {
				return x === row.id;
			})
		);
	}

	isSelectedParkings(row) {
		return (
			this.eventParkings.parkings &&
			this.eventParkings.parkings.find(x => {
				return x === row.id;
			})
		);
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	applyFilterPasses(filterValue: string) {
		this.passes.filter = filterValue.trim().toLowerCase();
	}

	applyFilterArmbands(filterValue: string) {
		this.armbands.filter = filterValue.trim().toLowerCase();
	}

	applyFilterParking(filterValue: string) {
		this.parkings.filter = filterValue.trim().toLowerCase();
	}

	checkPass($event, element) {
		if (!this.eventPasses.passes) {
			this.eventPasses.passes = [];
		}
		if ($event.checked) {
			this.eventPasses.passes.push(element.id);
		} else {
			this.eventPasses.passes = this.eventPasses.passes.filter(event => {
				return element.id !== event;
			});
		}
	}

	allPass() {
		const formdata = {
			eventId: this.eventId
		};
		this.eventService.allPassDownload(formdata).subscribe(res => {
			this.toastr.success('A PDF generálás elindult, ha elkészült emailben tajekoztatjuk', '');
		}, error => {
			this.toastr.warning(error.error.message, '');
		});
	}

	checkParkings($event, element) {
		if (!this.eventParkings.parkings) {
			this.eventParkings.parkings = [];
		}
		if ($event.checked) {
			this.eventParkings.parkings.push(element.id);
		} else {
			this.eventParkings.parkings = this.eventParkings.parkings.filter(
				event => {
					return element.id !== event;
				}
			);
		}
	}

	checkArmbands($event, element) {
		if (!this.eventArmbands.armbands) {
			this.eventArmbands.armbands = [];
		}
		if ($event.checked) {
			this.eventArmbands.armbands.push(element.id);
		} else {
			this.eventArmbands.armbands = this.eventArmbands.armbands.filter(
				event => {
					return element.id !== event;
				}
			);
		}
	}

	isSelected(row) {
	}

	getPasses() {
		let params = new HttpParams();
		this.passService.getPasses(params).subscribe(res => {
			this.collectionPasses = res.osszes;
			this.passes.data = res.elemek;
		});
	}

	getParkings() {
		let params = new HttpParams();
		this.parkingService.getParkings(params).subscribe(res => {
			this.collectionParkings = res.osszes;
			this.parkings.data = res.elemek;
		});
	}

	getArmbands() {
		let params = new HttpParams();
		this.armbandService.getArmbands(params).subscribe(res => {
			this.collectionArmbands = res.osszes;
			this.armbands.data = res.elemek;
		});
	}

	getMyLinks() {
		const modalRef = this.modalService.open(MyLinksComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = this.eventId;
		modalRef.result.then(() => {
		}).catch(() => {
		});
	}

	export() {
		this.eventService.downloadCsv(this.eventId).subscribe((res) => {
			var link = document.createElement("a");
			let file = new Blob([res], {type: 'text/csv;charset=utf-8;'});
			var fileUrl = URL.createObjectURL(file);
			link.setAttribute("href", fileUrl);
			link.setAttribute("download", 'data.csv');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

		}, error => {

			var string = new TextDecoder("utf-8").decode(error.error);
			let obj = JSON.parse(string);
			let message = obj['message'];

			this.toastr.error(message, '');
		});
	}

	save() {
		const searchData = this.form.value;
		const start = moment(searchData.from).format('YYYY-MM-DD');
		const end = moment(searchData.to).format('YYYY-MM-DD');
		this.form.controls['passes'].setValue(this.eventPasses.passes);
		this.form.controls['bracelets'].setValue(this.eventArmbands.armbands);
		this.form.controls['parkingIds'].setValue(this.eventParkings.parkings);
		this.form.controls['from'].setValue(start);
		this.form.controls['to'].setValue(end);
		this.eventService
			.modifyEvent(this.eventId, this.form.getRawValue())
			.subscribe(
				res => {
					if (res.status == 200) {
						this.toastr.success(
							'Az eseményt sikeresen frissítette!',
							''
						);
					} else {
						this.toastr.error('Hiba történt!', '');
					}
				},
				error => {
					this.toastr.error(error.error.message, 'Hiba');
				}
			);
	}
}
