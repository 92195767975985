import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';

@Injectable({providedIn: 'root'})
export class MyLinksService{
	constructor(private http: HttpClient) {

	}
	getMyLinks(id) {
		return this.http.get<any>(baseUrl + 'users/getMyUrls/'+id);
	}
}
