import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {InformationService} from './information.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'kt-information',
	templateUrl: './information.component.html',
	styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
	data: any[];

	constructor(private cdr: ChangeDetectorRef,private toastr: ToastrService,private router: Router,private informationService: InformationService) {
	}

	ngOnInit() {
		this.informationService.getAllEvents().subscribe((res) => {
			this.data = res;
		},error => {
			this.toastr.error(error.error.message);
		});
	}

	getEventStatistics(id) {
		this.router.navigateByUrl(`/information/${id}`);
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}
}
