import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HelperService} from './helper.service';
import {NewHelperComponent} from './new-helper/new-helper.component';
import {PlayHelperComponent} from './play-helper/play-helper.component';
import {NgxPermissionsService} from 'ngx-permissions';

@Component({
	selector: 'kt-helper',
	templateUrl: './helper.component.html',
	styleUrls: ['./helper.component.scss']
})
export class HelperComponent implements OnInit {
	dataSource = new MatTableDataSource<any>();
	displayedColumns = ['name', 'description', 'url', 'actions'];
	pageSize = 50;
	page = 1;
	role : boolean=false;
	nev = '';
	collection: number;
	rendezesirany = 'asc';

	constructor(private ngxPermissionService: NgxPermissionsService,private toastr: ToastrService, private confirmationDialogService: ConfirmationDialog, private modalService: NgbModal, private service: HelperService, private changeDetector: ChangeDetectorRef) {
	}

	ngAfterViewChecked() {
		this.changeDetector.detectChanges();
	}

	ngOnInit() {
		this.role = this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO');
		this.getData();
	}

	lapozas(event) {
		this.page = event;
		this.getData();
	}

	getData() {
		this.service.getHelper().subscribe((res) => {
			this.dataSource = res;
		});
	}

	neweditHelper(helpers?){
		const modalRef = this.modalService.open(NewHelperComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.id = helpers;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	playHelper(link){
		const modalRef = this.modalService.open(PlayHelperComponent, {
			size: 'lg',

			backdrop: 'static',
			keyboard: false
		});
		modalRef.componentInstance.link = link;
		modalRef.result.then(() => {
			this.getData();
		}).catch(() => {
		});
	}

	deleteHelper(id) {
		this.confirmationDialogService
			.confirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			.then((confirmed => {
				if (confirmed) {
					this.service.deleteHelper(id).subscribe((res) => {
						if (res.status == 200) {
							this.toastr.success('Sikeresesn törölte a segédletet', '');
							this.getData();
						} else {
							this.toastr.error('Hiba törléskor', '');
						}
					});
				} else {
					this.toastr.warning('Törlés elutasítva', '');
					this.getData();
				}
			}));

	}
}
