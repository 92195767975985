import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../../url.model';

@Injectable({providedIn: 'root'})
export class NicknameService {
	constructor(private http: HttpClient) {

	}
	newNickname(formdata) {
		return this.http.post<any>(baseUrl + 'users/changeNickName', formdata, {observe: 'response'});
	}
}
