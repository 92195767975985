import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ProfileService } from "./profile.service";
import { Logout } from "../../../core/auth";
import { Store } from "@ngrx/store";
import { AppState } from "../../../core/reducers";
import { ToastrService } from "ngx-toastr";
import moment from "moment";
import { ConfirmationDialog } from "../confirmation-dialog/confirmation-dialog.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmWithPasswordComponent } from "./confirm-with-password/confirm-with-password.component";
import {
	CropperDialogComponent,
	dataURLtoFile
} from "../cropper-dialog/cropper-dialog.component";
const urlLogo = "https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png";
const url = "/assets/media/avatar.png";

@Component({
	selector: "kt-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
	firstname;
	image: string | ArrayBuffer = null;
	lastname;
	file = null;
	userId;
	url = url;
	form = this.fb.group({
		firstname: [
			"",
			[
				Validators.required,
				Validators.pattern("^[a-zÁÉÚŐÓÜÖÍáéúőóüöíA-Z_-]{3,15}$")
			]
		],
		lastname: [
			"",
			[
				Validators.required,
				Validators.pattern('^[a-zÁÉÚŐÓÜÖÍáé úőóűüöíA-Z_-]{3,20}$')
			]
		],
		motherName: ["", Validators.required],
		birthPlace: ["", Validators.required],
		birthDate: ["", Validators.required],
		tutelaryName: [""]
	});

	formPass = this.fb.group({
		oldPassword: ["", Validators.required],
		newPassword: ["", [Validators.required,  Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
		newPasswordConfirm: ["", [Validators.required,  Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
		userId: [""]
	});

	name = new FormControl();

	public hasError = (controlName: string, errorName: string) => {
		return this.form.controls[controlName].hasError(errorName);
	};

	public hasPasswordError = (controlName: string, errorName: string) => {
		return this.formPass.controls[controlName].hasError(errorName);
	};
	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private modal: NgbModal,
		private confirmationDialogService: ConfirmationDialog,
		private toastr: ToastrService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private service: ProfileService
	) {}

	ngOnInit() {
		this.getMyData();
		this.firstname = localStorage.getItem("firstname");
		this.lastname = localStorage.getItem("lastname");
		this.userId = localStorage.getItem("userId");
		this.formPass.controls["userId"].setValue(this.userId);
	}




	openCropModal(event) {
		const ref = this.modal.open(CropperDialogComponent, {
			ariaLabelledBy: "modal-basic-title",
			size: "lg",
			backdrop  : 'static',
			keyboard  : false
		});
		ref.componentInstance.source = event;
		ref.result.then(result => {
			if (result) {
				this.url = result;
				this.file = dataURLtoFile(result, "file.png");
			}
		});
	}

	getMyData() {
		this.service.me().subscribe(res => {
			this.form.setValue({
				firstname: res.firstname,
				lastname: res.lastname,
				motherName: res.motherName,
				birthPlace: res.birthPlace,
				birthDate: res.birthDate,
				tutelaryName: res.tutelaryName
			});
			this.url = res.profileImageLink;
		});
	}

	passwor() {
		return (
			this.formPass.get("newPassword").value !=
			this.formPass.get("newPasswordConfirm").value
		);
	}

	strongPass(){
		if((this.formPass.get('newPassword').value).length<8){
			return false;
		}
	}

	saveMyData() {
		const birth = moment(this.form.controls["birthDate"].value).format(
			"YYYY-MM-DD"
		);

		const formData = new FormData();
		formData.append("firstname", this.form.controls["firstname"].value);
		formData.append("lastname", this.form.controls["lastname"].value);
		formData.append("birthDate", birth);
		formData.append("birthPlace", this.form.controls["birthPlace"].value);
		formData.append("motherName", this.form.controls["motherName"].value);
		if (this.file) {
			formData.append("profileImage", this.file);
		}

		this.service.saveData(formData).subscribe(
			res => {
				this.toastr.success("Adatok mentése sikeres volt", "");
				this.getMyData();
			},
			error => {
				this.toastr.error(error.error.message);
			}
		);
	}

	delete() {
		this.confirmationDialogService
			.confirm(
				"Törlés megerősítése!",
				"Biztosan törli szeretné profilját?"
			)
			.then(confirmed => {
				if (confirmed) {
					const modalRef = this.modal.open(
						ConfirmWithPasswordComponent,
						{ size: "sm" }
					);
				} else {
					this.toastr.warning("", "Törlés elutasítva");
					this.getMyData();
				}
			});
	}

	changePassword() {
		this.service.changePassword(this.formPass.getRawValue()).subscribe(
			res => {
				localStorage.clear();
				this.store.dispatch(new Logout());
			},
			error => {
				this.toastr.error(error.error.message, "HIBA");
			}
		);
	}
}
