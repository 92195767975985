import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EventsService} from '../events/events.service';
import {HttpParams} from '@angular/common/http';
import {UsersService} from '../users/users.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserImportService} from './user-import.service';
import {ToastrService} from 'ngx-toastr';

const urlLogo = 'https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png';
const url = 'https://mlsz-defba.firebaseapp.com/assets/media/add_photo.png';

@Component({
	selector: 'kt-user-import',
	templateUrl: './user-import.component.html',
	styleUrls: ['./user-import.component.scss']
})
export class UserImportComponent implements OnInit {
	available$ = new BehaviorSubject([]);
	passList;
	parkSlot;
	checkedPass = true;
	checkedBracelet = false;
	url = url;
	braceletsList;
	selectedPass;
	selectedParking;
	selectedEventId;
	selectedBracelet;
	form = this.fb.group({
		event: ['']
	});
	file: File;
	image: string | ArrayBuffer = null;
	send: boolean = false;

	constructor(private cdr: ChangeDetectorRef, private toastr: ToastrService, private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef, private service: EventsService, private userImportService: UserImportService, private uservice: UsersService) {
	}

	ngOnInit() {
		this.cdr.detectChanges();
		this.getEvents();
	}


	changeEvent(event) {
		if (event) {
			this.selectedEventId = event;
			this.getAvailablePass();
			this.getAvailableParkingSlots();
			this.getAvailableBracelets();
		} else {
		}
	}

	selectAccess(event) {
		if (event.value === 'pass') {
			this.selectedBracelet = null;
			this.checkedBracelet = false;
			this.checkedPass = true;

		} else {
			this.selectedPass = null;
			this.checkedBracelet = true;
			this.checkedPass = false;
		}
	}

	getEvents() {
		this.userImportService.getEvents().subscribe((res) => {
			this.available$.next(res);
		});

	}

	getAvailableBracelets() {
		this.userImportService.getAvailableBracelets(this.selectedEventId).subscribe((res) => {
			this.braceletsList = res;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	getAvailablePass() {
		this.userImportService.getAvailablePass(this.selectedEventId).subscribe((res) => {
			this.passList = res;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}

	
	getAvailableParkingSlots() {

		this.userImportService.getAvailableParkingSlots(this.selectedEventId).subscribe((res) => {
			this.parkSlot = res;
		}, error => {
			this.toastr.error(error.error.message, '');
		});
	}


	save() {
		const formData = new FormData();
		formData.append('file', this.file);
		formData.append('eventId', this.selectedEventId);
		// formData.append('parkingId', this.selectedParking);
		formData.append('passId ', this.selectedPass);

		const formData2 = new FormData();
		formData2.append('braceletId', this.selectedBracelet);
		formData2.append('file', this.file);
		formData2.append('eventId', this.selectedEventId);
		if (this.selectedBracelet) {
			this.userImportService.createUserGroupByBracelet(formData2).subscribe((res) => {
				this.toastr.success(
					'Sikeres file feltöltés!',
					''
				);
				this.selectedPass = null;
				this.selectedParking = null;
				this.selectedEventId = null;
				this.selectedBracelet = null;
				this.file = null;
				this.getEvents();
			}, error => {
				this.toastr.error(error.error.message, '');
			});
		} else {
			this.userImportService.createUserGroupByPass(formData).subscribe((res) => {
				this.toastr.success(
					'Sikeres file feltöltés!',
					''
				);
			}, error => {
				this.toastr.error(error.error.message, '');
			});
		}
	}

	onSelectFile(event) {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			this.file = event.target.files[0];
			reader.readAsDataURL(this.file); // read file as data url

			reader.onload = event => {
				this.image = reader.result;
				this.changeDetectorRef.detectChanges();
			};
		}
	}

}
