import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class InvitedService {
	constructor(private http: HttpClient) {

	}

	getInv(params)  {
		return this.http.get<any>(baseUrl + 'users/getInvitedPersonPages',{params: params});
	}
	//
	// globalSearch(params): Observable<any> {
	// 	return this.http.get<any>(baseUrl + 'users/globalSearch', {params: params});
	// }

	getInvited() {
		return this.http.get<any>(baseUrl + 'users/getInvitedPerson');

	}

	activateUser(formdata, multi = false): Observable<any> {
		return this.http.post<any>(
			baseUrl +
			(multi ? 'users/validateUserGroup' : 'users/validateUser'),
			formdata,
			{observe: 'response'}
		);
	}

	refuseUser(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/refuseUser', formdata, {observe: 'response'});
	}

	postFake(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/availableActivatorOptions', formdata, {observe: 'response'});
	}

	reportImage(form) {
		return this.http.post<any>(baseUrl + 'users/wrongProfileImage', form, {observe: 'response'});
	}

	attachable(formdata): Observable<any> {
		return this.http.post<any>(baseUrl + 'users/availableAttachableOptions', formdata, {observe: 'response'});
	}
}
