import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MatTableDataSource} from '@angular/material';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Store,} from '@ngrx/store';

import {AppState} from '../../../core/reducers';
import {UsersService} from './users.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationDialog} from '../confirmation-dialog/confirmation-dialog.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NewComponent} from './new/new.component';
import {FormBuilder, FormControl} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {debounceTime} from 'rxjs/operators';
import {InvitesFComponent} from './invites-f/invites-f.component';
import {NgxPermissionsService} from 'ngx-permissions';

export interface Users {
	id: string;
	userName: string;
	userRole: string;
}

@Component({
	selector: 'kt-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default
})
export class UsersComponent implements OnInit {
	dataSource = new MatTableDataSource<Users>();
	displayedColumns = ['username', '_roles', 'firstname', 'lastname', 'actions'];
	search = new FormControl();
	available$ = new BehaviorSubject([]);
	private subscriptions: Subscription[] = [];
	pageSize = 10;
	materror: boolean = false;
	page = 1;
	role: boolean = false;
	collection: number;
	form = this.fb.group({
		event: ['']
	});
	rendezesirany = 'asc';
	selectedEventId;

	constructor(
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal,
		private ngxPermissionService: NgxPermissionsService,
		private toastr: ToastrService,
		private confirmationDialogService: ConfirmationDialog,
		private store: Store<AppState>,
		private userService: UsersService) {
	}

	textareaEnterPressed($event: KeyboardEvent) {
		$event.preventDefault();
		$event.stopPropagation();
		this.getNewData();
	}

	szukites() {
		this.getNewData();
	}

	changeEvent(event) {
		this.selectedEventId = event;
		this.getNewData();
	}

	getEvents() {

		this.userService.getEvents().subscribe((res) => {
			this.available$.next(res);
		});
		// if (this.materror) {
		// 	this.form.controls['event'].setValue(1);
		// }
		// if (this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO')){
		// 	this.available$.next([{name: 'összes'}])
		// 	console.log('fejlesztő')
		// }
	}

	lapozas(event) {
		this.page = event;
		this.getNewData();
	}

	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	ngOnInit() {
		if (!this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO')) {
			this.materror = true;
		}
		if (this.ngxPermissionService.getPermissions().hasOwnProperty('FEJLESZTO') || this.ngxPermissionService.getPermissions().hasOwnProperty('ADMIN') || this.ngxPermissionService.getPermissions().hasOwnProperty('SUPERVISOR') || this.ngxPermissionService.getPermissions().hasOwnProperty('ALSUPERVISOR')) {
			this.role = true;
		} else {
			this.role = false;
		}
		this.search.valueChanges.pipe(debounceTime(500)).subscribe((str) => {
				if (this.selectedEventId) {
					this.getNewData();
				}
			}
		);

		if (this.materror) {
			if (this.selectedEventId) {
				this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
					this.getNewData());
			}
		} else {
			this.search.valueChanges.pipe(debounceTime(500)).subscribe(str =>
				this.getNewData());
		}
		this.getEvents();

		if (!this.materror) {
			this.getNewData();
		}
		this.cdr.detectChanges();
	}

	ngAfterViewChecked() {
		this.cdr.detectChanges();
	}

	newUser() {
		const modalRef = this.modalService.open(NewComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.result.then(() => {
			this.getNewData();
		}).catch(() => {
		});
	}


	getNewData() {
		let params = new HttpParams()
			.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
		if (this.search.value && this.search.value.length) {
			params = params.append('szoveg', this.search.value);
		}
		if (this.selectedEventId && this.selectedEventId !== 'Összes esemény') {
			params = params.append('eventId', this.selectedEventId);
		}
		this.userService.newGlobalSearch(params).subscribe((res) => {
			this.collection = res.osszes;
			this.dataSource.data = res.elemek;
		});
	}

	// getData() {
	// 	let params = new HttpParams()
	// 		.append('oldal', `${this.page - 1}`).append('meret', `${this.pageSize}`);
	// 	if (this.search.value && this.search.value.length) {
	// 		params = params.append('szoveg', this.search.value);
	// 	}
	// 	this.userService.globalSearch(params).subscribe((res) => {
	// 		this.collection = res.osszes;
	// 		this.dataSource.data = res.elemek;
	// 	});
	// }


	deleteUser(user) {
		this.confirmationDialogService
			.confirm('Törlés megerősítése!', 'Biztosan törli a kiválasztott rekordot?')
			.then((confirmed => {
				if (confirmed) {
					this.userService.deleteUser(user).subscribe((res) => {
						this.getNewData();
					}, error => {
						this.toastr.error(error.error.message, '');
					});
				} else {
					this.toastr.warning('', 'Törlés elutasítva');
					this.getNewData();
				}
			}));


	}

	sendE() {
		const modalRef = this.modalService.open(InvitesFComponent, {
			size: 'lg',
			backdrop: 'static',
			keyboard: false
		});
		modalRef.result.then(() => {
		}).catch(() => {
		});
	}
}

