import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MyLinksService} from './my-links.service';
import {FormBuilder} from '@angular/forms';

@Component({
	selector: 'kt-my-links',
	templateUrl: './my-links.component.html',
	styleUrls: ['./my-links.component.scss']
})
export class MyLinksComponent implements OnInit {
	@Input() public id;
	allnull: boolean = false;
	form = this.fb.group({
		admin: [''],
		cassa: [''],
		developer: [''],
		enduser: [''],
		supvisor: [''],
		subSupVisor: ['']
	});
	constructor(private fb: FormBuilder,private myLinkService: MyLinksService, public activeModal: NgbActiveModal) {
	}

	ngOnInit() {
		this.getData();
	}

	getData() {
		this.myLinkService.getMyLinks(this.id).subscribe((res) => {
			this.form.setValue({
				admin: res.admin,
				cassa: res.cassa,
				developer: res.developer,
				enduser: res.endUser,
				supvisor: res.supVisor,
				subSupVisor: res.subSupVisor

			});

		},error => {
				this.allnull = true;
		});
	}

	copyInputMessage(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}
}
