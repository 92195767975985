import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {baseUrl} from '../url.model';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ParkingService {
	constructor(private http: HttpClient) {

	}

	getParkings(params) {
		return this.http.get<any>(baseUrl + 'parkingSlots/all', {params: params});
	}

	getParking(id): Observable<any> {
		return this.http.get<any>(baseUrl + 'parkingSlots/' + id);
	}

	newParking(formdata) {
		return this.http.post<any>(baseUrl + 'parkingSlots', formdata, {observe: 'response'});
	}
	// window.open(baseUrl + `parkingSlots/loadParkingSample/${id}`, '_blank');
	downloadParking(id){
		return this.http.get(baseUrl + `parkingSlots/loadParkingSample/${id}`,{responseType:'arraybuffer'});
	}

	deleteParking(id): Observable<any> {
		return this.http.delete<any>(baseUrl + 'parkingSlots/' + id, {observe: 'response'});
	}
	deleteParkingwithPassword(id,password): Observable<any> {
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
			body: {
				password: password
			},
		};
		return this.http.delete<any>(baseUrl + 'parkingSlots/' + id, options);
	}
	modifyParking(id, formdata): Observable<any> {
		return this.http.put<any>(baseUrl + 'parkingSlots/' + id, formdata, {observe: 'response'});
	}
}
