// Angular
import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG
} from "@angular/platform-browser";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
	GestureConfig,
	MAT_DATE_LOCALE,
	MatAutocompleteModule,
	MatButtonModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDatepickerModule,
	MatDialogModule,
	MatExpansionModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatOptionModule,
	MatProgressSpinnerModule,
	MatSelectModule,
	MatTableModule,
	MatRadioModule
} from "@angular/material";
import { OverlayModule } from "@angular/cdk/overlay";
// Angular in memory
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
// Perfect Scroll bar
import {
	PERFECT_SCROLLBAR_CONFIG,
	PerfectScrollbarConfigInterface
} from "ngx-perfect-scrollbar";
// SVG inline
import { InlineSVGModule } from "ng-inline-svg";
// Env
import { environment } from "../environments/environment";
// Hammer JS
import "hammerjs";
// NGX Permissions
import { NgxPermissionsModule } from "ngx-permissions";
// NGRX
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// State
import { metaReducers, reducers } from "./core/reducers";
// Copmponents
import { AppComponent } from "./app.component";
// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { ThemeModule } from "./views/theme/theme.module";
// Partials
import { PartialsModule } from "./views/partials/partials.module";
// Layout Services
import {
	DataTableService,
	FakeApiService,
	KtDialogService,
	LayoutConfigService,
	LayoutRefService,
	MenuAsideService,
	MenuConfigService,
	MenuHorizontalService,
	PageConfigService,
	SplashScreenService,
	SubheaderService
} from "./core/_base/layout";
// Auth
import { AuthModule } from "./views/pages/auth/auth.module";
import { AuthService } from "./core/auth";
// CRUD
import {
	HttpUtilsService,
	LayoutUtilsService,
	TypesUtilsService
} from "./core/_base/crud";
// Config
import { LayoutConfig } from "./core/_config/layout.config";
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from "ngx-highlightjs";
import * as typescript from "highlight.js/lib/languages/typescript";
import * as scss from "highlight.js/lib/languages/scss";
import * as xml from "highlight.js/lib/languages/xml";
import * as json from "highlight.js/lib/languages/json";
import { AuthInterceptor } from "./views/theme/services/auth.interceptor";
import { ConfirmationDialogComponent } from "./views/pages/confirmation-dialog/confirmation-dialog.component";
import { ToastrModule } from "ngx-toastr";
import {
	NgbActiveModal,
	NgbModalModule,
	NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { NewComponent } from "./views/pages/users/new/new.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NewEventComponent } from "./views/pages/events/new-event/new-event.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { InvitesFComponent } from "./views/pages/users/invites-f/invites-f.component";
import { ColorPickerModule } from "ngx-color-picker";
import { MailTemplateComponent } from "./views/pages/mail-template/mail-template.component";
import { MyLinksComponent } from "./views/pages/my-links/my-links.component";
import { NewVoucherComponent } from "./views/pages/vouchers/new-voucher/new-voucher.component";
import { SetpermisComponent } from "./views/pages/users/user-permis/setpermis/setpermis.component";
import { NewandeditArmbandComponent } from "./views/pages/armband/newandedit-armband/newandedit-armband.component";
import { Ng2ImgMaxModule } from "ng2-img-max";
import { NgxEditorModule } from "ngx-editor";
import { ErrorModalComponent } from "./views/pages/error-modal/error-modal.component";
import { UserPassesComponent } from "./views/pages/users/user-passes/user-passes.component";
import { UserBraceletsComponent } from "./views/pages/users/user-bracelets/user-bracelets.component";
import { ForgotPassComponent } from "./views/pages/auth/login/forgot-pass/forgot-pass.component";
import { PrivacyEditComponent } from "./views/pages/privacypolicy/privacy-edit/privacy-edit.component";
import { ActivateModalComponent } from "./views/pages/invited/activate-modal/activate-modal.component";
import { ToastModule } from "./views/pages/toast";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { CheckDataComponent } from "./views/pages/redemption/details/check-data/check-data.component";
import { ConfirmWithPasswordComponent } from "./views/pages/profile/confirm-with-password/confirm-with-password.component";
import { ScopeMailComponent } from "./views/pages/events/scope-mail/scope-mail.component";
import { EventAttComponent } from "./views/pages/users/event-att/event-att.component";
import { BItemComponent } from "./views/pages/users/new/b-item/b-item.component";
import { HeroFilterPipe } from "./views/pages/users/event-att/filter.pipe";
import { NewParkingComponent } from "./views/pages/parking/new-parking/new-parking.component";
import { UserParkingComponent } from "./views/pages/users/user-parking/user-parking.component";
import { LoginAboutUsComponent } from "./views/pages/auth/login-about-us/login-about-us.component";
import { LoginPrivacyComponent } from "./views/pages/auth/login-privacy/login-privacy.component";
import { NewandeditPassComponent } from "./views/pages/passes/newandedit-pass/newandedit-pass.component";
import { NewHelperComponent } from "./views/pages/helper/new-helper/new-helper.component";
import { PlayHelperComponent } from "./views/pages/helper/play-helper/play-helper.component";
import { YouTubePlayerModule } from "@angular/youtube-player";
import {NicknameComponent} from './views/pages/invited/nickname/nickname.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	wheelSpeed: 0.5,
	swipeEasing: true,
	minScrollbarLength: 40,
	maxScrollbarLength: 300
};
export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);

	//return new TranslateHttpLoader(httpClient,
	//environment.feServerUrl + '/assets/i18n/', '.json');
}
export function initializeLayoutConfig(appConfig: LayoutConfigService) {
	// initialize app by loading default demo layout config
	return () => {
		if (appConfig.getConfig() === null) {
			appConfig.loadConfigs(new LayoutConfig().configs);
		}
	};
}

export function hljsLanguages(): HighlightLanguage[] {
	return [
		{ name: "typescript", func: typescript },
		{ name: "scss", func: scss },
		{ name: "xml", func: xml },
		{ name: "json", func: json }
	];
}

@NgModule({
	declarations: [
		PlayHelperComponent,
		NewHelperComponent,
		LoginAboutUsComponent,
		LoginPrivacyComponent,
		UserParkingComponent,
		NewParkingComponent,
		HeroFilterPipe,
		BItemComponent,
		EventAttComponent,
		ScopeMailComponent,
		ConfirmWithPasswordComponent,
		CheckDataComponent,
		ActivateModalComponent,
		PrivacyEditComponent,
		ForgotPassComponent,
		UserPassesComponent,
		UserBraceletsComponent,
		NewandeditArmbandComponent,
		AppComponent,
		ConfirmationDialogComponent,
		NewEventComponent,
		InvitesFComponent,
		MailTemplateComponent,
		MyLinksComponent,
		NewVoucherComponent,
		SetpermisComponent,
		ErrorModalComponent,
		NewandeditPassComponent,
		NicknameComponent
	],
	imports: [
		BrowserAnimationsModule,
		NgbModalModule,
		BrowserModule,
		Ng2ImgMaxModule,
		MatRadioModule,
		AppRoutingModule,
		HttpClientModule,
		ToastModule.forRoot(),
		NgxEditorModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		environment.isMockEnabled
			? HttpClientInMemoryWebApiModule.forRoot(FakeApiService, {
					passThruUnknownUrl: true,
					dataEncapsulation: false
			  })
			: [],
		NgxPermissionsModule.forRoot(),
		PartialsModule,
		CoreModule,
		OverlayModule,
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot({ stateKey: "router" }),
		StoreDevtoolsModule.instrument(),
		AuthModule.forRoot(),
		TranslateModule.forRoot(),
		MatProgressSpinnerModule,
		InlineSVGModule.forRoot(),
		ThemeModule,

		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: "toast-bottom-right"
		}),
		MatOptionModule,
		MatSelectModule,
		FormsModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatInputModule,
		MatIconModule,
		MatTableModule,
		MatCheckboxModule,
		MatDialogModule,
		MatButtonModule,
		ColorPickerModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatListModule,
		NgxMaterialTimepickerModule,
		NgxMaterialTimepickerModule.setLocale("hu-HU"),
		NgbTimepickerModule,
		YouTubePlayerModule
	],
	exports: [],
	providers: [
		// {provide: ErrorHandler, useClass: GlobalErrorHandler},
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: MAT_DATE_LOCALE, useValue: "hu-HU" },
		AuthService,
		HeroFilterPipe,
		LayoutConfigService,
		LayoutRefService,
		MenuConfigService,
		PageConfigService,
		KtDialogService,
		DataTableService,
		SplashScreenService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: GestureConfig
		},
		{
			// layout config initializer
			provide: APP_INITIALIZER,
			useFactory: initializeLayoutConfig,
			deps: [LayoutConfigService],
			multi: true
		},
		{
			provide: HIGHLIGHT_OPTIONS,
			useValue: { languages: hljsLanguages }
		},
		// template services
		SubheaderService,
		MenuHorizontalService,
		MenuAsideService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents: [
		PlayHelperComponent,
		NewHelperComponent,
		LoginAboutUsComponent,
		LoginPrivacyComponent,
		UserParkingComponent,
		NewParkingComponent,
		BItemComponent,
		EventAttComponent,
		ScopeMailComponent,
		ConfirmWithPasswordComponent,
		CheckDataComponent,
		ActivateModalComponent,
		PrivacyEditComponent,
		ForgotPassComponent,
		UserPassesComponent,
		UserBraceletsComponent,
		NewandeditArmbandComponent,
		ConfirmationDialogComponent,
		NewEventComponent,
		InvitesFComponent,
		NewandeditPassComponent,
		MailTemplateComponent,
		MyLinksComponent,
		NewVoucherComponent,
		SetpermisComponent,
		ErrorModalComponent,
		NicknameComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
