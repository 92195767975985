import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageTransform} from 'ngx-image-cropper';

@Component({
	selector: 'kt-cropper-dialog',
	templateUrl: './cropper-dialog.component.html',
	styleUrls: ['./cropper-dialog.component.scss']
})
export class CropperDialogComponent implements OnInit {
	@Input()
	source = null;
	canvasRotation = 0;
	croppedImage = null;
	showSpinner = true;
	transform: ImageTransform = {};

	constructor(public modal: NgbActiveModal) {
	}

	ngOnInit() {
	}

	imageCropped(event: any) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		this.showSpinner = false;
	}

	private flipAfterRotate() {
		const flippedH = this.transform.flipH;
		const flippedV = this.transform.flipV;
		this.transform = {
			...this.transform,
			flipH: flippedV,
			flipV: flippedH
		};
	}

	rotateLeft() {
		this.canvasRotation--;
		this.flipAfterRotate();
	}

	loadImageFailed() {
		this.showSpinner = false;
	}
}


export function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, {type: mime});
}
