// Angular
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewEncapsulation
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// RxJS
import { Observable, Subject, BehaviorSubject } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import { AuthNoticeService, AuthService, Login } from "../../../../core/auth";
import * as jwt_decode from "jwt-decode";
import { NgxPermissionsService, NgxRolesService } from "ngx-permissions";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ForgotPassComponent } from "./forgot-pass/forgot-pass.component";
import { ToastrService } from "ngx-toastr";
import { LoginAboutUsComponent } from "../login-about-us/login-about-us.component";
import { LoginPrivacyComponent } from "../login-privacy/login-privacy.component";

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
	USERNAME: "",
	PASSWORD: ""
};

@Component({
	selector: "kt-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: FormGroup;
	form: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	error: boolean = false;
	private unsubscribe: Subject<any>;
	permissions$;
	roles$;
	private returnUrl: any;

	reason = "";
	redirectError$ = new BehaviorSubject(false);

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private dialog: MatDialog,
		private toastr: ToastrService,
		private router: Router,
		private rolesService: NgxRolesService,
		private permissionsService: NgxPermissionsService,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.permissions$ = permissionsService.permissions$;
		this.unsubscribe = new Subject();
		this.roles$ = rolesService.roles$;
	}

	set loadingS(loading: boolean) {
		this.loading = loading;
	}
	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.initLoginForm();
		if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras) {
			const navState = this.router.getCurrentNavigation().extras.state;
			if (navState.reason) {
				this.redirectError$.next(true);
				this.reason = navState.reason;
			}
		}
	}

	ngOnDestroy(): void {
		this.loading = false;
	}

	aboutus() {
		const dialogRef = this.dialog.open(LoginAboutUsComponent, {
			width: "500px"
		});
	}

	adatkez() {
		const dialogRef = this.dialog.open(LoginPrivacyComponent, {
			width: "750px"
		});
	}
	initLoginForm() {
		this.form = this.fb.group({
			username: [
				DEMO_PARAMS.USERNAME,
				Validators.compose([Validators.required, Validators.email])
			],
			password: [
				DEMO_PARAMS.PASSWORD,
				Validators.compose([Validators.required])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		this.loading = true;
		const controls = this.form.controls;
		const authData = {
			username: controls["username"].value,
			password: controls["password"].value
		};
		this.auth.marciLogin(authData).subscribe(
			res => {
				localStorage.setItem("token", res.token);
				localStorage.setItem("role", res.role);

				this.permissionsService.loadPermissions([res.role]);

				const decoded = this.getDecodedAccessToken(res.token);
				this.auth.setFirstname(decoded.firstname);
				this.auth.setLastname(decoded.lastname);
				this.auth.setUser(decoded.sub);
				localStorage.setItem("userId", decoded.userId);
				this.loading = false;

				const redirectUrl = localStorage.getItem("redirectUrl");

				if (redirectUrl) {
					localStorage.removeItem("redirectUrl");
					this.router.navigateByUrl(redirectUrl);
				}
				this.router.navigate(["/"]);
			},
			error => {
				// console.log(error);
				if (error.status == 401) {
					this.toastr.error("Hibás felhasználónév vagy jelszó", "", {
						timeOut: 8000
					});
				}
				if (error.status > 500) {
					this.toastr.error("Server oldali hiba", "", {
						timeOut: 8000
					});
				}

				this.error = true;
				this.loading = false;
			}
		);
	}

	openModal() {
		const dialogRef = this.dialog.open(ForgotPassComponent, {
			width: "500px"
		});
	}

	getDecodedAccessToken(token: string): any {
		try {
			return jwt_decode(token);
		} catch (Error) {
			return null;
		}
	}
}
