import {Component, OnInit, OnDestroy, Inject, ChangeDetectorRef} from '@angular/core';
import {AnimationEvent} from '@angular/animations';

import {ToastData, TOAST_CONFIG_TOKEN, ToastConfig} from './toast-config';
import {ToastRef} from './toast-ref';
import {toastAnimations, ToastAnimationState} from './toast-animation';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['toast.component.scss'],
    animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
    animationState: ToastAnimationState = 'default';
    iconType: string;
    hibalista =[];
    public intervalId: number;

    constructor(
        readonly data: ToastData,
        readonly ref: ToastRef,
        public cdr: ChangeDetectorRef,
        @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
    ) {
        this.iconType = data.type === 'success' ? 'done' : data.type;
    }

    ngOnInit() {
        console.log(this.data.text);
        this.hibalista = [];
        for (let prop in (this.data.text as any)){
            if (this.data.text.hasOwnProperty(prop)){
               this.hibalista = this.hibalista.concat(this.data.text[prop])
            }
        }
        this.cdr.detectChanges();
        // this.intervalId = setTimeout(() => this.animationState = 'closing', 5000);
    }

    ngOnDestroy() {
        clearTimeout(this.intervalId);
    }

    close() {
        this.ref.close();
    }

    onFadeFinished(event: AnimationEvent) {
        const {toState} = event;
        const isFadeOut = (toState as ToastAnimationState) === 'closing';
        const itFinished = this.animationState === 'closing';

        if (isFadeOut && itFinished) {
            this.close();
        }
    }
}
